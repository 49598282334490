var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"card card-custom card-stretch gutter-b"},[_c('div',{staticClass:"card-body pt-3 pb-0"},[_c('div',{staticClass:"table-responsive"},[_c('v-data-table',{staticClass:"table custom-table table-head-custom table-vertical-center table-head-bg table-borderless",attrs:{"dense":"","headers":_vm.computedHeaders,"items":_vm.dataToShow,"item-key":"id","mobile-breakpoint":"0","footer-props":{
                            showFirstLastPage: true,
                            itemsPerPageText: 'Wyników na stronę',
                            pageText: '{0}-{1} na {2}',
                            itemsPerPageOptions: [10, 30, 50, 100]
                            },"items-per-page":10},scopedSlots:_vm._u([{key:"body",fn:function(ref){
                            var items = ref.items;
                            var headers = ref.headers;
return [_c('tbody',[_vm._l((items),function(result,index){return [_c('tr',{key:result.id,attrs:{"data-index":index}},[_c('td',[_vm._v(_vm._s(result.orderNo))]),_c('td',[_vm._v(_vm._s(result.date_created))]),_c('td',[_c('a',{staticClass:"btn btn-light btn-xs mr-2",attrs:{"title":"Edytuj wpis"},on:{"click":function($event){return _vm.editResult(result.order_id, result.id)}}},[_c('i',{staticClass:"fa fa-edit p-0 text-primary"})])])])]})],2)]}}])})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }