<template>
    <div class="row">
        <div class="col-xl-12">
            <div class="card card-custom card-stretch gutter-b">
                <div class="card-body pt-3 pb-0">
                    <div class="table-responsive">
                        <v-data-table
                            dense
                            :headers="computedHeaders"
                            :items="dataToShow"
                            class="table custom-table table-head-custom table-vertical-center table-head-bg table-borderless"
                            item-key="id"
                            mobile-breakpoint="0"
                            :footer-props="{
                                showFirstLastPage: true,
                                itemsPerPageText: 'Wyników na stronę',
                                pageText: '{0}-{1} na {2}',
                                itemsPerPageOptions: [10, 30, 50, 100]
                                }"
                            :items-per-page="10"
                        >
                            <template v-slot:body="{ items, headers }">
                                <tbody>
                                    <template v-for="(result, index) in items">
                                        <tr
                                            :key="result.id"
                                            :data-index="index"
                                        >
                                            <td>{{ result.orderNo }}</td>
                                            <td>{{ result.date_created }}</td>
                                            <td>
                                                <a
                                                    class="btn btn-light btn-xs mr-2"
                                                    title="Edytuj wpis"
                                                    @click="editResult(result.order_id, result.id)"
                                                >
                                                    <i class="fa fa-edit p-0 text-primary"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

    export default {
        name: "orderPanelLabListing",
        mounted() {
            this.$store.dispatch("orderLabList");
            this.$store.dispatch("getWaitingResults");
            this.$store.dispatch("researchLinesList");

            this.$store.dispatch(SET_BREADCRUMB, [
                { title: "Panel laboratorium - Wyniki laboratoryjne do zatwierdzenia", route: "orderPanelLab" }
            ]);
        },
        computed: {
            ...mapGetters(["waitingResults", "labOrders", "researchLinesRaw"]),
            computedHeaders() {
                return [
                    {
                    text: "Numer zlecenia laboratoryjnego",
                    value: "orderNo",
                    style: "min-width: 200px;"
                    },
                    {
                    text: "Data utworzenia dokumentu",
                    value: "date_created",
                    style: "min-width: 200px;"
                    },
                    {
                    text: "Akcja",
                    value: "",
                    style: "min-width: 200px;"
                    },
                ]
            },
            dataToShow() {
                if ( !this.waitingResults || this.waitingResults.length === 0 )
                    return [];
                
                return this.waitingResults.map( el => {
                    return {
                        orderNo: el.lab_order.number,
                        date_created: el.date_created.date.split(' ')[0],
                        order_id: el.lab_order.id,
                        id: el.id
                    }
                });
            }
        },
        methods: {
            editResult(orderId, resultId) {
                this.$router.push('/lab/orderResult/' + resultId + "/edit/" + orderId)
            }
        }
    };
</script>